import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import urlJoin from 'url-join';
import ls from 'local-storage';
import parseISO from 'date-fns/parseISO';
import isValidDate from 'date-fns/isValid';
import { capitalize, truncate, formatters } from 'utils';
import LazyThumbnail from 'components/common/LazyThumbnail';
import Icon from 'components/common/Icon';


class Table extends Component {
  constructor (props) {
    super(props);
    this.state = {
      activeColumns: [],
      columnControlVisible: false,
    };
    this.handleColumnChange = this.handleColumnChange.bind(this);
    this.handleDefaultColumnsClick = this.handleDefaultColumnsClick.bind(this);
    this.handleControlToggleClick = this.handleControlToggleClick.bind(this);
    this.handleClickOutsideColumnControl = this.handleClickOutsideColumnControl.bind(this);
    this.setDefaultColumns = this.setDefaultColumns.bind(this);
    this.renderColumnControl = this.renderColumnControl.bind(this);
    this.renderRow = this.renderRow.bind(this);
  }

  componentDidMount () {
    const { model } = this.props;
    const persistedColumns = ls.get(`objectList-${model}-activeColumns`);
    if (persistedColumns && persistedColumns.length > 0) {
      this.setState({ activeColumns: persistedColumns });
    } else {
      this.setDefaultColumns();
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { model } = this.props;
    const { activeColumns, columnControlVisible } = this.state;
    if (!isEqual(activeColumns, prevState.activeColumns)) {
      ls.set(`objectList-${model}-activeColumns`, activeColumns);
    }

    if (!columnControlVisible && prevState.columnControlVisible) {
      document.removeEventListener('click', this.handleClickOutsideColumnControl);
    }
  }

  handleControlToggleClick (e) {
    e.preventDefault();
    const { columnControlVisible } = this.state;
    this.setState({ columnControlVisible: !columnControlVisible }, () => {
      setTimeout(() => {
        if (this.state.columnControlVisible) {
          document.addEventListener('click', this.handleClickOutsideColumnControl);
        }
      }, 100);
    });
  }

  handleClickOutsideColumnControl (e) {
    if (this.columnMenuRef && !this.columnMenuRef.contains(e.target)) {
      this.setState({ columnControlVisible: false });
    }
  }

  handleColumnChange (e) {
    const { activeColumns } = this.state;
    const fieldName = e.target.value;
    if (e.target.checked) {
      this.setState({ activeColumns: [...activeColumns, fieldName] });
    } else {
      this.setState({ activeColumns: activeColumns.filter(item => item !== fieldName) });
    }
  }

  handleDefaultColumnsClick (e) {
    e.preventDefault();
    this.setDefaultColumns();
  }

  setDefaultColumns () {
    const { columns } = this.props;
    this.setState({ activeColumns: columns.filter(c => c.default).map(c => c.field) });
  }

  renderColumnControl () {
    const { columns } = this.props;
    const { activeColumns, columnControlVisible } = this.state;
    const columnMenuStyle = { display: columnControlVisible ? 'block' : 'none' };

    return (
      <th className="column-control">
        <div className="dropdown dropdown-right">
          <a
            href="#columns"
            className="column-control-toggle tooltip tooltip-left"
            data-tooltip="Show/Hide Columns"
            onClick={this.handleControlToggleClick}
          >
            <Icon name="view_column" />
          </a>
          <ul className="menu" style={columnMenuStyle} ref={node => this.columnMenuRef = node}>
            {columns.filter(c => c.optional).map(({ field, label }) => {
              const labelText = label || capitalize(field);
              const isEnabled = activeColumns.includes(field);
              return (
                <li key={field} className="menu-item">
                  <label className="form-checkbox">
                    <input type="checkbox" value={field} checked={isEnabled} onChange={this.handleColumnChange} />
                    <i className="form-icon" /> {labelText}
                  </label>
                </li>
              );
            })}
            <li className="divider" />
            <li className="menu-item">
              <a href="#reset-columns" onClick={this.handleDefaultColumnsClick}>Default Columns</a>
            </li>
          </ul>
        </div>
      </th>
    );
  }

  renderRow (item) {
    const { columns, detailBaseUrl, detailTargetBlank, imageClickToggleSelection, selectedItemIds, onToggleItemSelect } = this.props;
    const { activeColumns } = this.state;
    const columnsForRow = columns.filter(c => activeColumns.includes(c.field));
    const itemDetailUrl = urlJoin(detailBaseUrl, item.id, '/');
    const isSelected = selectedItemIds.includes(item.id);

    return (
      <tr key={item.id} className={isSelected ? 'selected' : null}>
        <td className="cb">
          <label className="form-checkbox">
            <input type="checkbox" onChange={() => onToggleItemSelect(item)} checked={isSelected} />
            <i className="form-icon" />
          </label>
        </td>
        <td className="id" colSpan={columnsForRow.length === 0 ? 2 : null}>
          <a href={itemDetailUrl} rel="noopener" target={detailTargetBlank ? '_blank' : '_self'}>{item.id}</a>
        </td>
        {columnsForRow.map(({ field, image, link, wrap, numberFormat }, i) => {
          let value = item[field];
          // eslint-disable-next-line quotes
          const valueAsDate = parseISO(value);
          if (image) {
            const style = { display: 'block', width: 100, height: 60 };
            value = (
              <a
                href={imageClickToggleSelection ? '#select' : itemDetailUrl}
                rel="noopener"
                target={detailTargetBlank ? '_blank' : '_self'}
                style={style}
                onClick={imageClickToggleSelection ? e => { e.preventDefault(); onToggleItemSelect(item); } : null}
              >
                {!!item[field] && <LazyThumbnail src={item[field]} alt={item.id} width={100} height={60} />}
              </a>
            );
          } else if (wrap) {
            value = <span title={value}>{truncate(value, 100)}</span>;
          } else if (typeof value === 'boolean') {
            value = value ? <Icon name="check_circle" size={20} className="bool-true" /> : <Icon name="cancel" size={20} className="bool-false" />;
          } else if (isValidDate(valueAsDate)) {
            value = <span title={formatters.shortDateTime(valueAsDate)}>{formatters.relativeDate(valueAsDate)}</span>;
          } else if (numberFormat) {
            value = formatters[numberFormat](value) || '–';
          } else if (!value) {
            value = '–';
          } else if (typeof value === 'string') {
            value = value.length > 30 ? <span title={value} className="clip">{truncate(value, 30)}</span> : value;
          } else if (Array.isArray(value)) {
            value = value.join(', ');
            value = value.length > 30 ? <span title={value} className="clip">{truncate(value, 30)}</span> : value;
          }
          const props = {
            colSpan: i === columnsForRow.length - 1 ? 2 : null,
            key: `${item.id}-${field}`,
            className: classNames({
              wrap: wrap,
              thumb: image,
            }),
          };

          if (link) {
            value = <a href={itemDetailUrl} rel="noopener" target={detailTargetBlank ? '_blank' : '_self'}>{value}</a>;
          }

          // eslint-disable-next-line react/jsx-key
          return <td {...props}><div>{value}</div></td>;
        })}
      </tr>
    );
  }

  render () {
    const { columns, items, selectedItemIds, sortField, sortDir, onSort, onToggleSelectAll } = this.props;
    const { activeColumns } = this.state;
    const allItemsSelected = items.every(({ id }) => selectedItemIds.includes(id));

    return (
      <table className="object-list-table" cellPadding="0" cellSpacing="0">
        <thead>
          <tr>
            <th className="cb">
              <label className="form-checkbox">
                <input
                  type="checkbox"
                  onChange={onToggleSelectAll}
                  checked={allItemsSelected}
                />
                <i className="form-icon" />
              </label>
            </th>
            <th className="id">ID</th>
            {columns.filter(c => activeColumns.includes(c.field)).map(({ field, label, sortable }) => {
              const thClasses = classNames({
                sortable,
                'sorted-desc': field === sortField && sortDir === 'desc',
                'sorted-asc': field === sortField && sortDir === 'asc',
              });
              const handleClick = e => {
                e.preventDefault();
                sortable && onSort(field);
              };
              return (
                <th key={field} className={thClasses}>
                  <a href={sortable ? `#${field}` : null} onClick={handleClick}>{label || capitalize(field)}</a>
                </th>
              );
            })}
            {this.renderColumnControl()}
          </tr>
        </thead>
        <tbody>
          {items.map(this.renderRow)}
        </tbody>
      </table>
    );
  }
}

Table.propTypes = {
  model: PropTypes.string,
  detailBaseUrl: PropTypes.string,
  detailTargetBlank: PropTypes.bool,
  imageClickToggleSelection: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.object),
  items: PropTypes.arrayOf(PropTypes.object),
  selectedItemIds: PropTypes.arrayOf(PropTypes.string),
  sortField: PropTypes.string,
  sortDir: PropTypes.string,
  onSort: PropTypes.func,
  onToggleItemSelect: PropTypes.func,
  onToggleSelectAll: PropTypes.func,
};

export default Table;
