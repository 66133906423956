import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import camelize from 'camelize';
import urlJoin from 'url-join';
import { urls } from 'app-constants';
import ErrorBoundary from 'components/common/ErrorBoundary';
import Icon from 'components/common/Icon';
import MediaTypeIcon from 'components/common/MediaTypeIcon';
import TruncateText from 'components/common/TruncateText';
import { DeleteOverlay } from 'components/common/inlines';


class Item extends Component {
  constructor (props) {
    super(props);
    this.state = {
      attachedItem: null,
      error: false,
      processing: false,
    };

    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount () {
    const { linkType } = this.props;
    const itemId = this.props[linkType];
    this.fetchData(linkType, itemId);
  }

  componentDidUpdate (prevProps, prevState) {
    const { linkType, media, title } = this.props;

    if (
      linkType !== prevProps.linkType ||
      (linkType === 'media' && media !== prevProps.media) ||
      (linkType === 'title' && title !== prevProps.title)
    ) {
      const itemId = this.props[linkType];
      if (!itemId) {
        this.setState({ attachedItem: null, processing: false, error: null });
      } else {
        this.fetchData(linkType, itemId);
      }
    }
  }

  handleDeleteClick (e) {
    const { onDeleteClick } = this.props;
    e.stopPropagation();
    onDeleteClick();
  }

  fetchData (type, id) {
    if (!id) {
      return null;
    }
    const baseUrl = urls[`${type}DataBase`];

    const url = urlJoin(baseUrl, id, '?format=json');
    this.setState({ processing: true });
    fetch(url, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          this.setState({ error: response.statusText });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => {
        this.setState({
          attachedItem: data,
          processing: false,
        });
      })
      .catch(err => {
        this.setState({ processing: false });
        console.error(err);
      });
  }

  getNameText () {
    const { name, nameSource } = this.props;
    const { attachedItem } = this.state;

    switch (nameSource) {
      case 'item_name':
        return attachedItem ? attachedItem.name : '';
      default:
        return name || '';
    }
  }

  getDescriptionText () {
    const { description, descriptionSource } = this.props;
    const { attachedItem } = this.state;

    switch (descriptionSource) {
      case 'item_long_desc':
        return attachedItem ? attachedItem.longDescription : '';
      case 'item_short_desc':
        return attachedItem ? attachedItem.shortDescription : '';
      default:
        return description || '';
    }
  }

  renderThumbnail () {
    const { linkType } = this.props;
    const itemId = this.props[linkType];
    const { attachedItem } = this.state;

    if (itemId) {
      return attachedItem ? (
        <div className="title-builder-item-thumbnail mr-4">
          <img src={attachedItem.thumbnail} alt="thumbnail" width="100" height="60" />
        </div>
      ) : (
        <div className="title-builder-item-thumbnail mr-4">
          <Icon name="steppers" />
        </div>
      );
    } else {
      return (
        <div className="title-builder-item-thumbnail mr-4">
          <Icon name="content_copy" />
        </div>
      );
    }
  }

  renderIcon () {
    const { linkType, external } = this.props;
    const { attachedItem } = this.state;

    let icon;
    if (external) {
      icon = <Icon name="open_in_new" />;
    } else {
      icon = attachedItem && (
        linkType === 'media'
          ? <MediaTypeIcon type={attachedItem.type.toLowerCase()} />
          : <Icon name={attachedItem.iconName} />
      );
    }

    const classes = classNames({
      'title-builder-item-icon': true,
      inactive: !attachedItem,
    });

    return <div className={classes} title={attachedItem && attachedItem.type}>{icon}</div>;
  }

  renderFormFields () {
    const {
      prefix, counter, id, shouldDelete, parent, linkType, media, title, external, linkUrl, label, name, nameSource,
      description, descriptionSource, backgroundImage, backgroundVideo, loopVideo,
    } = this.props;

    return (
      <>
        {!!id && <input type="hidden" name={`${prefix}-id`} value={id} />}
        {shouldDelete && <input type="hidden" name={`${prefix}-DELETE`} value="1" />}
        <input type="hidden" name={`${prefix}-parent`} value={parent} />
        <input type="hidden" name={`${prefix}-index`} value={counter} />
        <input type="hidden" name={`${prefix}-link_type`} value={linkType || 'media'} />
        {external && <input type="hidden" name={`${prefix}-external`} value="1" />}
        <input type="hidden" name={`${prefix}-link_url`} value={linkUrl || ''} />
        <input type="hidden" name={`${prefix}-label`} value={label || ''} />
        <input type="hidden" name={`${prefix}-name`} value={name || ''} />
        <input type="hidden" name={`${prefix}-name_source`} value={nameSource || ''} />
        <input type="hidden" name={`${prefix}-description`} value={description || ''} />
        <input type="hidden" name={`${prefix}-description_source`} value={descriptionSource || ''} />
        {!!media && <input type="hidden" name={`${prefix}-media`} value={media} />}
        {!!title && <input type="hidden" name={`${prefix}-title`} value={title} />}
        {!!backgroundImage && <input type="hidden" name={`${prefix}-background_image`} value={backgroundImage} />}
        {!!backgroundVideo && <input type="hidden" name={`${prefix}-background_video`} value={backgroundVideo} />}
        {!!loopVideo && <input type="hidden" name={`${prefix}-loop_video`} value={loopVideo} />}
      </>
    );
  }

  render () {
    const { active, error, onClick, shouldDelete, linkType, external, linkUrl, label } = this.props;
    const { attachedItem } = this.state;

    const containerClasses = classNames({
      active,
      error,
      'title-builder-item': true,
      'to-delete': shouldDelete,
    });

    const baseUrl = urls[`${linkType}Base`];
    const itemLabel = [label, this.getNameText()].filter(s => !!s).join(' - ');

    return (
      <ErrorBoundary suppressMessage>
        <div className={containerClasses} onClick={onClick}>
          {this.renderIcon()}
          {this.renderThumbnail()}

          <div className="title-builder-item-details">
            <h6 className="mb-0"><TruncateText text={itemLabel} numChars={45} forceBreak /></h6>
            {attachedItem && (
              <div className="meta mt-1 lh-1">
                {linkType === 'media' ? 'Media' : 'Title'}:&nbsp;<a href={`${baseUrl}${attachedItem.id}/`} target="_blank" rel="noopener noreferrer">{attachedItem.id}</a>
              </div>
            )}
            {external && !!linkUrl && (
              <div className="meta mt-1 lh-1">
                Link:&nbsp;
                <a href={linkUrl} target="_blank" rel="noopener noreferrer">
                  <TruncateText text={(linkUrl || '').replace(/^https?:\/\//, '')} numChars={15} />
                </a>
              </div>
            )}
          </div>

          {this.renderFormFields()}

          {shouldDelete && <DeleteOverlay onUndoClick={this.handleDeleteClick} />}

          <span
            className="btn-delete tooltip tooltip-left"
            key={shouldDelete}
            onClick={this.handleDeleteClick}
            data-tooltip={shouldDelete ? 'Undo Remove' : 'Remove Item'}
          >
            {shouldDelete ? <Icon name="undo" /> : <Icon name="delete" fill />}
          </span>

          <span className="btn-move" title="Drag to sort">
            <Icon name="menu" />
          </span>
        </div>
      </ErrorBoundary>
    );
  }
}

Item.propTypes = {
  counter: PropTypes.number,
  active: PropTypes.bool,
  error: PropTypes.bool,
  onClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  prefix: PropTypes.string,
  id: PropTypes.string,
  itemId: PropTypes.string,
  parent: PropTypes.string,
  shouldDelete: PropTypes.bool,
  linkType: PropTypes.oneOf(['media', 'title']),
  external: PropTypes.bool,
  linkUrl: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  nameSource: PropTypes.string,
  description: PropTypes.string,
  descriptionSource: PropTypes.string,
  media: PropTypes.string,
  title: PropTypes.string,
  backgroundImage: PropTypes.string,
  backgroundVideo: PropTypes.string,
  loopVideo: PropTypes.bool,
};

export default Item;
